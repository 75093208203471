<template>
  <div>
    <b-card>
      <b-row>
        <b-col class="text-right">
          <b-button @click="noticeList">목록</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" class="my-1">
          <b-card-title class="text-center">
            <div class="m-2">
              <h2>{{title}}</h2>
            </div>
            <div class="text-right">
              <span>
                작성자 : {{userId}} |
                등록일자 : {{createdAt | yyyyMMdd }}
              </span>
            </div>
          </b-card-title>
          <hr>
          <b-card-body>
            <b-card-text>
            {{content}}
            </b-card-text>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
  </div>

</template>
<script>
import {
  BCard, BCardBody, BCardTitle, BRow, BCol, BImg, BCardText, BLink, BButton, BDropdown, BDropdownItem, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { apolloClient } from '@/vue-apollo'
import { FindManyNoticeBoardDocument } from '@/generated/graphql'

export default {
  components: {
      BCard, BCardBody, BCardTitle, BRow, BCol, BImg, BCardText, BLink, BButton, BDropdown, BDropdownItem, BAlert,
  },
  // 커스텀 필터 설정
  filters : {
    // filter로 쓸 filter ID 지정
    yyyyMMdd : function(value){ //  yyyy MM dd 형태로 만들어줄거기 때문에 이렇게 이름을 지었음
      // 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
      if(value == '') return '';

      // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
      var js_date = new Date(value);

      // 연도, 월, 일 추출
      var year = js_date.getFullYear();
      var month = js_date.getMonth() + 1;
      var day = js_date.getDate();

      // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
      if(month < 10){
        month = '0' + month;
      }

      if(day < 10){
        day = '0' + day;
      }

      // 최종 포맷 (ex - '2021-10-08')
      return year + '-' + month + '-' + day;
    }
  },
  data() {
    return {
      idx:"",
      title:"",
      content : "",
      userId : "",
      createdAt : "",
      updateAt : "",
    }
  },

  async mounted(){
      //1.게시번호 Parameter로 넘겨받음
      console.log(this.$route.params);
      this.idx = this.$route.params.idx;

      //2.해당 게시번호로 데이터 가져오기

      const { data } = await apolloClient.query({
        query: FindManyNoticeBoardDocument
      })

      this.idx = data.findManyBoard[this.idx].idx
      this.title = data.findManyBoard[this.idx].title
      this.content = data.findManyBoard[this.idx].content
      this.userId = data.findManyBoard[this.idx].userId
      this.createdAt = data.findManyBoard[this.idx].createdAt
      this.updateAt = data.findManyBoard[this.idx].updateAt
    },
    methods:{
      noticeList() {
        this.$router.go(-1);
      }
    }
}
</script>
